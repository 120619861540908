<template>
  <div style="padding-bottom: 30px;">
    <div>
      <div class="buzhou" v-if="status === 0">
        <div style="text-align: right;margin-bottom: 20px;">
          <el-button @click="returnPage">返回</el-button>
        </div>
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="yangshi">
              1. 填写企业信息
            </div>
          </el-col>
          <el-col :span="12">
            <div class="yangshi2">
              2. 等待系统审核
            </div>
          </el-col>
          <!--<el-col :span="8">-->
          <!--  <div class="yangshi3">-->
          <!--    3. 完成企业认证-->
          <!--  </div>-->
          <!--</el-col>-->
        </el-row>
      </div>

      <div class="buzhou" v-if="status === 1">
        <div style="text-align: right;margin-bottom: 20px;">
          <el-button @click="returnPage">返回</el-button>
        </div>
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="yangshi">
              1. 填写企业信息
            </div>
          </el-col>
          <el-col :span="12">
            <div class="yangshi22">
              2. 等待系统审核
            </div>
          </el-col>
          <!--<el-col :span="8">-->
          <!--  <div class="yangshi3">-->
          <!--    3. 完成企业认证-->
          <!--  </div>-->
          <!--</el-col>-->
        </el-row>
      </div>

      <div class="buzhou" v-if="status === 2">
        <div style="text-align: right;margin-bottom: 20px;">
          <el-button @click="returnPage">返回</el-button>
        </div>
        <el-row :gutter="15">
          <el-col :span="8">
            <div class="yangshi">
              1. 填写企业信息
            </div>
          </el-col>
          <el-col :span="8">
            <div class="yangshi22">
              2. 等待系统审核
            </div>
          </el-col>
          <!--<el-col :span="8">-->
          <!--  <div class="yangshi33">-->
          <!--    3. 完成企业认证-->
          <!--  </div>-->
          <!--</el-col>-->
        </el-row>
      </div>

      <div class="content">
        <!--填写企业信息-->
        <div v-if="status == 0">
          <div v-if="approve_status == 'false'">
            <span style="color: red;">不合格原因: {{form.reason}}</span>
          </div>
          <el-form ref="form" :model="form" label-width="100px" :rules="rules" label-position="top">
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="营业执照">
                  <div class="upload_bg_yingyezhizhao">
                    <el-upload
                        :limit="1"
                        :class="{hide_box: businessLicenseupload_btn}"
                        :on-change="businessLicensechange"
                        :on-success="successUpload2"
                        :action="action"
                        :file-list="fileList"
                        list-type="picture-card"
                        :on-preview="businessLicensePreview"
                        :on-remove="businessLicensehandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 60px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 16px;font-weight: 500;color: #ff9b05;margin-top: 15px;">上传营业执照</div>
                    </el-upload>
                    <el-dialog :visible.sync="businessLicensedialogVisible">
                      <img width="100%" :src="businessLicensedialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="企业名称">
                  <el-input
                      clearable
                      v-model="form.company_name"
                      placeholder="请输入企业名称"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="社会信用代码">
                  <el-input
                      clearable
                      v-model="form.credit_code"
                      placeholder="请输入社会信用代码"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <!--<el-form-item label="企业机构代码">-->
                <!--  <el-input-->
                <!--      clearable-->
                <!--      v-model="form.institution_code"-->
                <!--      placeholder="请输入企业机构代码用于登录"/>-->
                <!--</el-form-item>-->
                <el-form-item label="企业简称">
                  <el-input
                    clearable
                    v-model="form.facilitator_abbreviation"
                    placeholder="请输入简称"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="企业注册日期">
                  <el-date-picker
                      v-model="form.registration_date"
                      type="date"
                      value-format="yyyy年MM月dd日"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册资本(万元人民币)">
                  <el-input
                      clearable
                      v-model="form.registered_capital"
                      placeholder="请输入注册资本"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-form-item label="注册地址">
                <div style="display: flex;align-items: center;">
                  <el-cascader
                    ref="refCascader"
                    :options="city"
                    v-model="form.registration_place"
                    @change="handleChange"></el-cascader>
                  <el-input
                      style="margin-left: 20px;"
                      clearable
                      v-model="form.detailed_address"
                      placeholder="请输入详细地址"/>
                </div>
              </el-form-item>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法定代表人身份证正面">
                  <div class="upload_bg">
                    <el-upload
                        :limit="1"
                        :class="{hide_box: upload_btn}"
                        :on-change="change"
                        :on-success="successUpload"
                        :action="action"
                        :file-list="fileList1"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传身份证正面</div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法定代表人身份证反面">
                  <div class="upload_bg_shenfenzheng">
                    <el-upload
                        :limit="1"
                        :class="{hide_box: oppositeupload_btn}"
                        :on-change="oppositechange"
                        :on-success="successUpload1"
                        :action="action"
                        :file-list="fileList2"
                        list-type="picture-card"
                        :on-preview="oppositePreview"
                        :on-remove="oppositehandleRemove">
                      <!--<i class="el-icon-plus"></i>-->
                      <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                      <div style="font-size: 12px;font-weight: 500;color: #ff9b05;">上传身份证反面</div>
                    </el-upload>
                    <el-dialog :visible.sync="oppositedialogVisible">
                      <img width="100%" :src="oppositedialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="法定代表人">
                  <el-input
                      clearable
                      v-model="form.legal_person"
                      placeholder="请输入法定代表人"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法定代表人身份证">
                  <el-input
                      clearable
                      v-model="form.corporate_identity_card"
                      placeholder="请输入法定代表人身份证"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="联系人姓名">
                  <el-input
                      clearable
                      v-model="form.realname"
                      placeholder="请输入联系人姓名"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系人手机">
                  <el-input
                      clearable
                      v-model="form.phone"
                      placeholder="请输入联系人手机号用于登录"/>
                </el-form-item>
              </el-col>
            </el-row>

            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="12">-->
            <!--    <el-form-item label="联系人邮箱">-->
            <!--      <el-input-->
            <!--          clearable-->
            <!--          v-model="form.email"-->
            <!--          placeholder="请输入联系人邮箱"/>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
          </el-form>
          <!--按钮-->
          <div style="margin-top: 50px;text-align: center;margin-bottom: 30px;">
            <el-button style="width: 200px !important;" @click="quxiao">取消</el-button>
            <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="tijiao">提交</el-button>
          </div>
        </div>

        <!--等待系统审核-->
        <div v-if="status == 1">
          <div style="text-align: center;margin-top: 80px;">
            <img src="@/assets/images/login/shenhe.png" alt="" style="width: 176px;height: 176px;">
            <!--<img src="@/assets/images/login/shenhetongguo.png" alt="" style="width: 176px;height: 176px;">-->
          </div>
          <div style="text-align: center;margin-top: 30px;">
            <span style="font-size: 18px;font-weight: 400;color: #2a2a42;">正在审核中</span>
          </div>
          <div style="margin-top: 80px;text-align: center;padding-bottom: 150px;">
            <!--<el-button @click="chushihua" style="width: 400px;background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;">初始化配置</el-button>-->
          </div>
        </div>

        <!--审核完成 初始化配置-->
        <!--<div v-if="status == 2" style="padding-top: 80px;padding-bottom: 80px;">-->
        <!--  <el-form ref="form" :model="form" label-width="100px" label-position="top">-->
        <!--    <el-row :gutter="15">-->
        <!--      <el-col :span="12" style="text-align: center;">-->
        <!--        <div class="chushihuabg" style="text-align: left;" @click="jiagepeizhi">-->
        <!--          <span style="font-size: 24px;font-weight: 700;color: #2d2f47;">服务区域及价格配置</span>-->
        <!--        </div>-->
        <!--        <div style="text-align: left;width: 400px;margin: auto;margin-top: 50px;">-->
        <!--          <el-form-item label="结算方式">-->
        <!--            <el-select-->
        <!--                clearable-->
        <!--                v-model="form.authentication_status"-->
        <!--                placeholder="请选择"-->
        <!--                class="ele-fluid">-->
        <!--              <el-option label="月结" value="1"/>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <!--      </el-col>-->

        <!--      <el-col :span="12">-->
        <!--        <div class="chushihuabg1" style="text-align: left;" @click="peizhi">-->
        <!--          <span style="font-size: 24px;font-weight: 700;color: #2d2f47;">服务项目配置</span>-->
        <!--        </div>-->
        <!--        <div style="text-align: left;width: 400px;margin: auto;margin-top: 50px;">-->
        <!--          <el-form-item label="开票方式">-->
        <!--            <el-select-->
        <!--                clearable-->
        <!--                v-model="form.authentication_status"-->
        <!--                placeholder="请选择"-->
        <!--                class="ele-fluid">-->
        <!--              <el-option label="无发票" value="1"/>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <!--      </el-col>-->
        <!--    </el-row>-->
        <!--  </el-form>-->
        <!--</div>-->

      </div>
    </div>

    <!--计价公式组件-->
    <!--<Pricingformula  v-if="showPricingformula == true" @showJijia="jijia"/>-->

    <!--项目配置模块-->
    <!--<projectConfiguration v-if="showpeizhi == true" @showxiangmu="xiangmu"/>-->

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

// 引入计价公式组件
import Pricingformula from './components/Pricingformula.vue'
//引入服务项目配置
import projectConfiguration from './components/projectConfiguration.vue'

//引入的接口
// 引入服务商注册接口
import {getfind_register, serviceregister, ocr, ocrocrRecognizeIdcard, query_register} from '@/api/serviceregister'

// 用来请求公共
import axios from "axios";

export default {
  components:{
    Pricingformula,
    projectConfiguration
  },
  data(){
    return{
      form:{
        company_name:'',
        credit_code:'',
        registered_capital:'',
        registration_date:'',
        detailed_address:'',
        legal_person:'',
        corporate_identity_card:'',
      },
      // 验证规则
      rules: {
        realname: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' },
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        id_number:[
          { required: true, message: '身份证号不能为空'},
          {pattern:/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: '不是合法的身份证号！',trigger: 'blur'}
        ],
        id_valid_time: [
          { required: true, message: '请选择身份证有效期', trigger: 'change' },
        ],
        serve_district: [
          { required: true, message: '请选择区域', trigger: 'serve_district' },
        ],
        drivers_number:[
          { required: true, message: '请输入驾驶证号码', trigger: 'blur' },
        ],
        drivers_valid_time:[
          { required: true, message: '请选择驾驶证有效期', trigger: 'change' },
        ],
        drivers_type:[
          { required: true, message: '请选择准驾车型', trigger: 'change' },
        ],
        status:[
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        // driver_type:[
        //   { required: true, message: '请选择司机类型', trigger: 'change' }
        // ],
        facilitator_id: [
          { required: true, message: '请选择服务商', trigger: 'change' }
        ],
        vehicle_id:[
          { required: true, message: '请选择绑定车辆', trigger: 'change' }
        ],
        serve_time:[
          { required: true, message: '请选择服务时间', trigger: 'change' }
        ],
        serve_type: [
          { type: 'array', required: true, message: '请选择服务类型', trigger: 'change' }
        ]
      },

      // 上传接口
      action: setting.commonurl + '/upload/business_license',
      // 上传请求头部
      // headers:{
      //   'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      // },

      // 身份证正面
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList1:[],

      //身份证反面
      oppositedialogImageUrl:'',
      oppositedialogVisible:false,
      oppositeupload_btn:false,
      fileList2:[],

      //营业执照
      businessLicenseupload_btn:false,
      businessLicensedialogVisible:false,
      businessLicensedialogImageUrl:'',
      fileList:[],

      //步骤
      status:0,

      //计价公式模块
      showPricingformula:false,

      //服务项目配置
      showpeizhi:false,

      //城市列表
      city:[],
      xiugai:false,
      registration_place:[],

      appid: '',
      approve_status:'true',

      value:[]

    }
  },

  mounted() {
    // 获取城市
    this.getCity();

    // if(this.$route.query.appid == undefined){
    //   this.appid = localStorage.getItem('appid');
    // }else {
    //   this.appid = this.$route.query.appid;
    // }

    if(this.$route.query.approve_status != undefined){
      // 审核状态
      this.approve_status = this.$route.query.approve_status;
      //appid
      this.appid = this.$route.query.appid;
      if(this.approve_status == 'false'){
        this.getquery_register();
      }
    }


    // this.getInfo();
  },

  methods:{
    // 获取城市列表
    getCity(){
      axios({
        method: 'GET',
        url: setting.commonurl + '/city_list',
      }).then(res => {
        // console.log(res)
        this.city = res.data.data;
      })
    },

    //查询服务商注册入住信息接口
    getquery_register(){
      let params = {
        appid: this.appid
      }
      query_register(params).then(res => {
        console.log(res)
        if(res.code == 200){
          this.form = res.data;
          //营业执照
          if(this.form.business_license != ''){
            let data = {
              url: this.form.business_license
            };
            this.businessLicenseupload_btn = true;
            this.fileList.push(data);
          }

          // 身份证正面
          if(this.form.legal_person_identify_front != ''){
            let data = {
              url: this.form.legal_person_identify_front
            };
            this.upload_btn = true;
            this.fileList1.push(data);
          }

          // 身份证反面
          if(this.form.legal_person_identify_reverse != ''){
            let data = {
              url: this.form.legal_person_identify_reverse
            };
            this.oppositeupload_btn = true;
            this.fileList2.push(data);
          }


        }else {
          this.$message.error(res.msg)
        }
      })
    },

    //查询服务商注册入住接口
    getInfo(){
      let params = {
        appid: this.appid
      }
      getfind_register(params).then(res => {
        console.log(res)
        if(res.code == 200){
          if(res.data.is_register == true){
            // 跳转到审核步骤
            this.status = 1;
          }
        }
      })
    },

    //返回上级页面
    returnPage(){
      this.$router.go(-1);
    },

    //身份证正面
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_front = file.data.urls;


      // ocr身份证 识别
      let data = {
        url: file.data.urls
      }
      ocrocrRecognizeIdcard(data).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.form.legal_person = res.data.face.data.name
          this.form.corporate_identity_card = res.data.face.data.idNumber
        }else {
          this.$message.error(res.msg);
        }
      })

    },

    //身份证反面
    oppositePreview(file){
      this.oppositedialogImageUrl = file.url;
      this.oppositedialogVisible = true;
    },
    oppositehandleRemove(file,fileList){
      this.oppositeupload_btn = false;
    },
    oppositechange(){
      this.oppositeupload_btn = true;
    },
    //上传成功
    successUpload1(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_reverse = file.data.urls;
    },

    // 营业执照
    businessLicensePreview(file){
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange(){
      this.businessLicenseupload_btn = true;
    },
    //上传成功
    successUpload2(file,fileList){
      console.log(file);
      console.log(fileList)
      // 上传成功后的列表
      this.form.business_license = file.data.urls;

      // ocr营业执照 识别
      let data = {
        url: file.data.urls
      }
      ocr(data).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.form.company_name = res.data.companyName;
          this.form.credit_code = res.data.creditCode;
          this.form.registered_capital = res.data.registeredCapital;
          this.form.registration_date = res.data.RegistrationDate;
          this.form.detailed_address = res.data.businessAddress;
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    //级联选择器
    handleChange(value) {
      this.value = value;
      this.form.registration_place = value;
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
      this.xiugai = true
      this.registration_place = area;
    },

    //点击取消
    quxiao(){
      // 返回上级页面
      this.$router.go(-1)
    },
    //点击提交
    tijiao(){
      console.log(this.xiugai)
      // 判断下是否修改了注册地址
      if(this.xiugai == true){
        this.form.registration_place = this.registration_place
      }else {
        this.form.registration_place = this.form.registration_place
      }
      // 调用注册服务商接口
      serviceregister(this.form).then(res => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          localStorage.setItem('appid',res.data.appid);
          this.status = 1;
        }else {
          this.form.registration_place = this.value;
          this.$message.error(res.msg);
        }
      })
    },
    //点击初始化配置
    chushihua(){
      this.status = 2;
    },

    //点击服务区域及价格配置
    jiagepeizhi(){
      this.showPricingformula = true;
    },
    //接收子组件传递的值
    jijia(value){
      this.showPricingformula = value;
    },

    //点击服务项目配置
    peizhi(){
      this.showpeizhi = true;
    },
    //接收子组件传递的值
    xiangmu(value){
      this.showpeizhi = value;
    },

  },

}
</script>

<style scoped lang="scss">
  .content{
    width: 80%;
    margin: auto;
    margin-top: 50px;
    background: #FFFFFF;
    padding: 20px;
  }

  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }

  .chushihuabg{
    width: 400px;
    background-image: url("../../assets/images/login/fuwuquyu.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 20px 30px 20px;
    margin: auto;
    cursor: pointer;
  }
  .chushihuabg1{
    width: 400px;
    background-image: url("../../assets/images/login/fuwuxiangmu.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 20px 30px 20px;
    margin: auto;
    cursor: pointer;
  }

  .buzhou{
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }
  .yangshi{
    //background: #FF9B05;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    background-image: url("../../assets/images/login/diyibu.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .sanjiao{
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid #FF9B05;
    border-bottom: 20px solid transparent;
  }
  .yangshi2{
    //background: #E4E5E9;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #242832;
    cursor: pointer;
    background-image: url("../../assets/images/login/dierbu.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .yangshi22{
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    background-image: url("../../assets/images/login/dierbuactive.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .yangshi3{
    //background: #E4E5E9;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #242832;
    cursor: pointer;
    background-image: url("../../assets/images/login/disanbu.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .yangshi33{
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    background-image: url("../../assets/images/login/disanbuactive.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
</style>
